.feedback {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--content-padding);
  max-width: 1000px;
}
.default__card > span {
  display: block;
}
.default__card > p {
  display: block;
  margin: 5px 0 0;
}
.default__card-controls {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
