.users-list__item {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--bg-color-secondary);
  border-spacing: 1px;
  border-radius: 3px;
  overflow: hidden;
  transition: 250ms ease-out;
  background-color: var(--bg-color);
}
.users-list__item:hover {
  box-shadow: var(--box-shadow);
  transform: scale(1.02);
}
.users-list__item > img {
  width: 100%;
  position: relative;
}
.users-list__desc {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.users-list__desc > span {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
