.users-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 10px;
}
@media (min-width: 1650px) {
  .users-list {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1200px) and (max-width: 1649px) {
  .users-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .users-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
